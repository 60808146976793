<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div v-show="!isEmpty" class="py-3 container inner-container">
    <h1 class="dynamic-sub-title-search">{{ $t('page.white-book') }}</h1>
    <p v-if="isEmpty" class="text-center pa-15 searchEmpty">{{ $t("search.not.found", {name: query}) }}</p>
    <div v-else>
      <v-row class="my-md-8 my-3">
        <v-col
          v-for="item in list"
          :key="item.id"
          cols="12"
          lg="3"
          md="6"
          sm="6"
        >
          <Item :item="item" type="white-book" />
        </v-col>
      </v-row>
      <div v-if="has_next" class="d-flex justify-center mb-5">
        <Main-btn @click="more">{{ $t('action.look-more') }}</Main-btn>
      </div>
    </div>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import indexList from "@/components/page/indexList.js";
export default {
  mixins: [indexList],
  props: {
    query: String,
  },
  components: {
    Item: () => import("../item/item.vue"),
  },
  computed: {
    indexApi() {
      return this.$api.paper.public.index;
    },
    otherParams() {
      return {
        per: 4,
        search: this.query,
      };
    },
  },
};
</script>