/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import qs from "qs";
export default {
  data: () => ({
    indexData: null,
    page: 1,
    indexLoading: false,
    countLoading: false,
  }),
  computed: {
    list() {
      if (!this.indexData) return [];
      return this.indexData.data;
    },
    isEmpty() {
      return _isEmpty(this.list);
    },
    pager() {
      if (!this.indexData) return null;
      return this.indexData.pager;
    },
    has_next() {
      if (!this.pager) return false;
      return this.pager.has_next;
    },
    siteData() {
      return this.$store.getters['base/siteData']
    },
    site_id() {
      if(!this.siteData) return ''
      return this.siteData.id;
    },
    routeQuery() {
      return qs.parse(this.$route.query.q);
    },
    routeTime() {
      if (!this.routeQuery) return "";
      return this.routeQuery.time || "";
    },
    routeCategory() {
      if (!this.routeQuery) return [];
      if (!this.routeQuery.category) return [];
      return this.routeQuery.category || [];
    },
    paramsTimeKey() {
      //TODO
      return "created_at";
    },
    defaultParams() {
      let params = {
        site_id: this.site_id,
        pager: 1,
        page: this.page,
      }
      if (!_isEmpty(this.routeCategory)) {
        params.category = this.routeCategory;
      }
      if (this.routeTime) {
        params.filter = { [this.paramsTimeKey]: this.routeTime }
      }
      params.sort = { [this.paramsTimeKey]: "desc" };
      return params;
    },
    otherParams() {
      // TODO
      return {
        per: 4
      }
    },
    params() {
      return {
        ...this.defaultParams,
        ...this.otherParams
      }
    },
    indexApi() {
      // TODO
      return null;
    },
    createdCallIndex() {
      // TODO
      return true;
    },
    showEmptyBlock() {
      return this.isEmpty && this.hasFilter && !this.indexLoading
    },
    showBackBtn() {
      return !_isEmpty(this.routeQuery);
    },
    hasFilter() {
      return !_isEmpty(this.routeQuery)
    },
    dataEmptyText () {
      if(this.hasFilter) return this.$t('data.filter.month.empty')
      return this.$t('data.empty')
    },
    actionBackText() {
      if(this.hasFilter) return this.$t('action.clear-filter')
      return this.$t('action.back')
    },
    countParams() {
      // TODO
      return {
        site_id: this.site_id
      }
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.resetData();
        this.getIndex();
      },
    },
  },
  async created() {
    if (!this.createdCallIndex) return;
    await this.count()
    await this.getIndex();
  },
  methods: {
    handleData() {
      //TODO
    },
    async count() {
      if (!this.countApi) return;
      this.$store.dispatch("base/loading");
      this.countLoading = true
      try {
        const res = await this.countApi(this.countParams);
        const count = res.data
        if(count > 0) return
        this.$router.replace({name: 'develop'}) 
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("base/loading", false);
        this.countLoading = false
      }
    },
    async getIndex() {
      if(this.hasFilter) {
        await this.index()
        return 
      }

      if(this.needFirstFetch && this.firstGetIndex) {
        await this.firstGetIndex()
      }
      await this.index()
      this.afterGetIndex()
    },
    async index() {
      if (!this.indexApi) return;
      this.$store.dispatch("base/loading");
      this.$store.commit(`base/setMainApiLoadDone`, false)
      this.indexLoading = true
      try {
        const res = await this.indexApi(this.params);
        if (!this.indexData) {
          this.indexData = res;
          this.handleData();
          return;
        }
        this.indexData.data = [...this.indexData.data, ...res.data];
        this.indexData.pager = res.pager;
        this.handleData();
      } catch (err) {
        console.error(err);
      } finally {
        this.indexLoading = false
        this.$store.dispatch("base/loading", false);
        this.$root.$emit("indexFetchDone");
        this.$store.commit(`base/setMainApiLoadDone`, true)
      }
    },
    afterGetIndex() {
      this.$root.$emit("setDynamicSubTitle");
    },
    resetData() {
      this.page = 1;
      if(!this.indexData) return
      this.indexData.data = [];
      this.indexData.pager = null;
    },
    more() {
      this.page += 1;
      this.index();
    },
  },
};
